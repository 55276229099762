import { Component, Inject, Injector, OnInit, HostListener } from '@angular/core';
import { runInInjectionContext } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { LanguagesService } from '@shure/shared/angular/utils/i18n';

import { environment } from '../environments/environment';

@Component({
	selector: 'sh-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public appVersion: string;
	public clientVersion: string | undefined;
	public showNotifications: boolean;

	public readonly isSmallDevice = this.breakpointService.isLteSmall;

	constructor(
		private readonly languageService: LanguagesService,
		private metaTagService: Meta,
		public oktaIntf: OktaInterfaceService,
		private breakpointService: BreakpointService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment,
		private injector: Injector
	) {
		this.appVersion = this.appEnv.appVersion;
		this.clientVersion = this.appEnv.clientVersion;
		this.showNotifications = Boolean(this.appEnv.cdmFeatureFlags?.showNotificationsInHeader);
	}

	//
	// The purpose of this code is to support the single logout functionality
	// whilst using session storage for okta tokens
	//
	// window:focus events are triggered only the first time the user clicks
	// in a window after having the cursor focused elsewhere.
	//
	@HostListener('window:focus', ['$event'])
	public onFocus(_event: FocusEvent): void {
		// the oktaSessionGuard must run in an injection context, which is why this
		// code isn't a simple function call.
		runInInjectionContext<void>(this.injector, () =>
			oktaSessionGuard(<ActivatedRouteSnapshot>{}, <RouterStateSnapshot>{})
		);
	}

	public ngOnInit(): void {
		this.languageService.getPreferredCultureCode();
		if (environment.production) {
			this.metaTagService.addTags([{ name: 'robots', content: 'index' }]);
		} else {
			this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
		}
	}
}
